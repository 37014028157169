import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {ToastService} from 'ng-uikit-pro-standard';
import {
  AdvertWithMessagesDTO,
  ContactAdminDTO,
  EnquirerThreadDTO,
  InMailDTO,
  ThreadMessageDTO,
  User
} from '../../../global/interfaces';
import {EventService, InMailService} from '../../../services';
import {AdminUrlService, AdminWhosWhoService} from '../services';
import {ImageService, UserService} from '../../../global/services';
import {EMPTY_GUID} from 'projects/common/shared';
import {MessageTypeEnum, StatusEnum} from '../../../global/enums';

@Component({
  selector: 'app-admin-messages-to-sellers',
  templateUrl: './admin-messages-to-sellers.component.html',
  styleUrls: ['./admin-messages-to-sellers.component.scss']
})
export class AdminMessagesToSellersComponent implements OnInit {
  page = 1;
  count = 0;
  pageSizes = [5, 10, 20, 50];
  pageSize = 5;

  isBusy = false;
  adverts: AdvertWithMessagesDTO[] = [];
  filterForm: FormGroup;
  assignedOptions: any;
  daysAgoOptions: any[];
  currentUser: User;
  siteAdmins: ContactAdminDTO[];

  markingAsActioned = false;
  sendingReply = false;

  protected readonly EMPTY_GUID = EMPTY_GUID;
  unActionedOnly: boolean = true;
  replyText: { [enquirerId: string]: string } = {};

  selectedThread = 0;

  constructor(
    private inMailService: InMailService,
    private fb: FormBuilder,
    private whosWhoService: AdminWhosWhoService,
    private userService: UserService,
    public adminUrl: AdminUrlService,
    public imageService: ImageService,
    private toast: ToastService,
    private eventService: EventService
  ) {}

  ngOnInit() {
    this.loadAdverts();
    this.initForm();
    this.fetchAssignees();

    this.userService.loadCurrentUser().then(() => {
      this.currentUser = this.userService.CurrentUser;
    });
  }

  initForm() {
    this.filterForm = this.fb.group({
      search: [''],
      dateRange: [''],
      assignedTo: [''],
    });
  }

  get f() {
    return this.filterForm.value;
  }

  loadAdverts() {
    this.isBusy = true;

    const dto = {
      offset: (this.page - 1) * this.pageSize,
      limit: this.pageSize,
      filters: {
        unActionedOnly: this.unActionedOnly
      }
    };

    this.inMailService.getPrivateMails(dto).then(res => {
      this.adverts = res.results;
      this.count = res.totalItems;
      this.isBusy = false;

      console.log(" *(* Loaded Adverts: ", res)
      console.log(" Total Adverts: ", this.count)

    }).catch(() => {
      this.isBusy = false;
      this.toast.error('Failed to load messages');
    });
  }

  onTableDataChange(page: number) {
    this.page = page;
    this.loadAdverts();
  }

  handlePageSizeChange(event: any) {
    this.pageSize = event.target.value;
    this.page = 1;
    this.loadAdverts();
  }

  filterToMe() {
    if (this.filterForm.value.assignedTo !== this.currentUser.contactId) {
      this.filterForm.patchValue({assignedTo: this.currentUser.contactId});
    } else {
      this.filterForm.patchValue({assignedTo: EMPTY_GUID});
    }
    this.loadAdverts();
  }

  fetchAssignees() {
    this.whosWhoService.getSiteAdmins({component: 'whos-who'}, true).then((res) => {
      this.siteAdmins = res.results;
      this.assignedOptions = this.whosWhoService.getAssignedOptions(res.results);
    });
  }

  assignedTo(assignedTo: string) {
    return this.siteAdmins?.find(x => x.id === assignedTo)?.contactName;
  }

  async onMarkAsActioned(message: ThreadMessageDTO) {
    this.markingAsActioned = true;

    try {
      await this.inMailService.markAsActioned(message.inMailId);
      message.isRead = true;
      this.toast.success('Message marked as actioned', 'Success', {opacity: 0.98});
    } catch {
      this.toast.error('Failed to mark message as actioned', 'Error', {opacity: 0.98});
    } finally {
      this.markingAsActioned = false;
    }
  }

  async sendReply(advert: AdvertWithMessagesDTO, enquirerId: string, replyToMessage: ThreadMessageDTO) {
    if (!this.replyText[enquirerId]) return;

    this.sendingReply = true;
    try {
      const dto = {
        toContactId: enquirerId,
        fromContactId: advert.vendorContactId,
        advertId: advert.advertId,
        subject: `re. ${replyToMessage.subject}`,
        body: this.replyText[enquirerId],
        statusId: StatusEnum.Active,
        isPrivate: false,
        inReplyToId: replyToMessage.inMailId
      } as InMailDTO;

      await this.inMailService.sendMail(dto);

      // Find all unactioned messages in the thread and mark them as actioned
      const thread = advert.enquirerThreads.find(t => t.enquirer.id === enquirerId);
      if (thread) {
        const unActionedMessages = thread.messages.filter(m => !m.isActioned && !m.inReplyToMailId);

        await Promise.all(
          unActionedMessages.map(msg => {
              this.onMarkAsActioned(msg);
              this.eventService.InMailEvent.emit({ messageType: MessageTypeEnum.UnreadSellerMessagesDecrement })
            }
          )
        );
      }

      this.replyText[enquirerId] = '';
      this.toast.success('Reply sent successfully', 'Success', {opacity: 0.98} );
      this.loadAdverts();
    } catch {
      this.toast.error('Failed to send reply', 'Error', {opacity: 0.98});
    } finally {
      this.sendingReply = false;
    }
  }

  onActionedOnlyChanged() {
    this.loadAdverts();
  }

  isIncomingMessage(message: ThreadMessageDTO): boolean {
    return !message.inReplyToMailId;
  }

  hasUnactionedMessages(thread: EnquirerThreadDTO): boolean {
    return thread.messages.some(m => !m.isActioned && this.isIncomingMessage(m));
  }

  getUnactionedCount(thread: EnquirerThreadDTO): number {
    return thread.messages.filter(m => !m.isActioned && !m.inReplyToMailId).length;
  }

  protected readonly StatusEnum = StatusEnum;
}
