import {Injectable} from '@angular/core';
import {ApiService} from "../global/services/index";
import {DataService} from "./data.service";
import { InMailFolderEnum } from '../global/enums/index';
import {EnquiryInmailDTO, InMailDTO, SearchResultDTO} from '../global/interfaces/index';

@Injectable()
export class InMailService {

  constructor(private apiClient: ApiService, private data: DataService) {
  }

  private serviceUrl = '/api/inMail';

  public getFolderMail(folder: InMailFolderEnum, page: number, perPage: number): Promise<InMailDTO[]> {
    const url = `${this.data.apiUrl}${this.serviceUrl}/folder/${folder}?page=${page}&perPage=${perPage}`;
    return this.apiClient.get({url}) as Promise<InMailDTO[]>;
  }

  public markAsRead(inMailId): Promise<any> {
    const url = `${this.data.apiUrl}${this.serviceUrl}/${inMailId}/markAsRead`;
    return this.apiClient.get({url}) as Promise<any>;
  }

  public markAsActioned(inMailId): Promise<any> {
    const url = `${this.data.apiUrl}${this.serviceUrl}/${inMailId}/mark-as-actioned`;
    return this.apiClient.get({url}) as Promise<any>;
  }

  public markAllAsRead(): Promise<any> {
    const url = `${this.data.apiUrl}${this.serviceUrl}/mark-all-read`;
    return this.apiClient.get({url}) as Promise<any>;
  }

  public getFolderCounts(): Promise<any> {
    const url = `${this.data.apiUrl}${this.serviceUrl}/count`;
    return this.apiClient.get({url}) as Promise<any>;
  }

  public deleteInMail(inMail: InMailDTO): Promise<any> {
    const url = `${this.data.apiUrl}${this.serviceUrl}/${inMail.id}`;
    return this.apiClient.delete({url}) as Promise<any>;
  }

  public moveToFolder(inMail: InMailDTO, toFolder: InMailFolderEnum): Promise<any> {
    const url = `${this.data.apiUrl}${this.serviceUrl}/${inMail.id}/moveToFolder/${toFolder}`;
    return this.apiClient.put({url}) as Promise<any>;
  }

  public sendMail(inMail: InMailDTO): Promise<any> {
    const url = `${this.data.apiUrl}${this.serviceUrl}`;
    return this.apiClient.post({url, data: inMail}) as Promise<any>;
  }

  public getPrivateMailsForAdvert(advertId: string, contactId: string): Promise<EnquiryInmailDTO[]> {
    const url = `${this.data.apiUrl}/api/listings/${advertId}/private-inmails/${contactId}`;
    return this.apiClient.get({url}) as Promise<EnquiryInmailDTO[]>;
  }

  public getPrivateMails(search: any): Promise<SearchResultDTO> {
    let url = `${this.data.apiUrl}${this.serviceUrl}/private`;
    url += '?query=' + encodeURIComponent(JSON.stringify(search));

    return this.apiClient.get({url}) as Promise<SearchResultDTO>;
  }
}
