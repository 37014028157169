import {Injectable} from '@angular/core';
import {ToastService} from 'ng-uikit-pro-standard';
import {AdminDashboardStatsDTO} from '../../../interfaces/DTOs/Admin/admin-dashboard-statsDTO.interface';
import {DataService} from "../../../services";
import {
  AdminStaffCallDailyTotalsDTO,
  AdminStaffCallRatioStatsDTO,
  AdminStaffCallRatioTotalsDTO,
  SimpleChartDataDTO, SiteTotalsDTO
} from "../../../global/interfaces";
import {ApiService, HandlerService} from "../../../global/services";
import {} from "../../../global/enums";

@Injectable()
export class AdminStatDataService {

  private serviceUrl = '/api/adminStatData';


  constructor(
    private apiClient: ApiService,
    private data: DataService,
    private toast: ToastService,
    private handler: HandlerService) {
    // this.logging.componentName = "AdminStatDataService";
  }

  getActiveCustomers(assigneeId: string, isWeekly: boolean): Promise<SimpleChartDataDTO[]> {
    let url = `${this.data.apiUrl}${this.serviceUrl}/activeCustomers`;

    if (assigneeId) {
      url += `?assigneeId=${assigneeId}&isWeekly=${isWeekly}`
    } else {
      url += `?isWeekly=${isWeekly}`
    }

    return this.apiClient.get({url}) as Promise<SimpleChartDataDTO[]>;
  }

  getLiveAdverts(assigneeId: string, isWeekly: boolean): Promise<SimpleChartDataDTO[]> {
    let url = `${this.data.apiUrl}${this.serviceUrl}/liveAdverts`;

    if (assigneeId) {
      url += `?assigneeId=${assigneeId}&isWeekly=${isWeekly}`
    } else {
      url += `?isWeekly=${isWeekly}`
    }

    return this.apiClient.get({url}) as Promise<SimpleChartDataDTO[]>;
  }

  getSalesByMarque(): Promise<SimpleChartDataDTO[]> {
    const url = `${this.data.apiUrl}${this.serviceUrl}/salesByMarque`;
    return this.apiClient.get({url}) as Promise<SimpleChartDataDTO[]>;
  }

  getSalesByCustomer(assigneeId: string): Promise<SimpleChartDataDTO[]> {
    let url = `${this.data.apiUrl}${this.serviceUrl}/salesByCustomer`;

    if (assigneeId) {
      url += `?assigneeId=${assigneeId}`
    }

    return this.apiClient.get({url}) as Promise<SimpleChartDataDTO[]>;
  }

  getAdminStats(assigneeId: string): Promise<AdminDashboardStatsDTO> {
    let url = `${this.data.apiUrl}/api/dashboard/getAdminStats`;

    if (assigneeId) {
      url += `?assigneeId=${assigneeId}`
    }

    return this.apiClient.get({url}) as Promise<AdminDashboardStatsDTO>;
  }

  //
  getStaffCallRatioStats(): Promise<AdminStaffCallRatioStatsDTO[]> {
    let url = `${this.data.apiUrl}${this.serviceUrl}/staff-call-ratio-stats`;
    return this.apiClient.get({url}) as Promise<AdminStaffCallRatioStatsDTO[]>;
  }

  getStaffCallRatioTotals(): Promise<AdminStaffCallRatioTotalsDTO[]> {
    let url = `${this.data.apiUrl}${this.serviceUrl}/staff-call-ratio-totals`;
    return this.apiClient.get({url}) as Promise<AdminStaffCallRatioTotalsDTO[]>;
  }

  getStaffCallDailyTotals(): Promise<AdminStaffCallDailyTotalsDTO[]> {
    let url = `${this.data.apiUrl}${this.serviceUrl}/staff-call-daily-totals`;
    return this.apiClient.get({url}) as Promise<AdminStaffCallDailyTotalsDTO[]>;
  }

  getSiteTotals(): Promise<SiteTotalsDTO> {
    let url = `${this.data.apiUrl}${this.serviceUrl}/site-totals`;
    return this.apiClient.get({url}) as Promise<SiteTotalsDTO>;
  }
}
