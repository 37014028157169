import {Injectable} from '@angular/core';
import {DataService} from "../../../services";
import {ApiService} from "../../../global/services";
import {WhosWhoDTO, WhosWhoSearchDTO} from "../../../interfaces/DTOs/Admin/whos-who.dto.interface";
import {
  ContactAdminDTO,
  ContactDTO,
  ContactSearchDTO, BidLimitedCustomerInfoDTO,
  CustomerInternalInfoDTO,
  TypedSearchResultDTO, UnverifiedContactDTO
} from "../../../global/interfaces";
import {CustomerInvoicesComponent} from "../../main/customer/customer-invoices/customer-invoices.component";
import {StatusEnum} from '../../../global/enums';

@Injectable()
export class AdminWhosWhoService {

  private serviceUrl = '/api/whoswho';

  constructor(
    private apiClient: ApiService,
    private data: DataService) {
  }

  getSiteAdmins(query: ContactSearchDTO, useCache = false): Promise<TypedSearchResultDTO<ContactAdminDTO>> {
    let url = `${this.data.apiUrl}/api/contacts/internal`;
    if (useCache) {
      url += '?cache=true';
    }
    return this.apiClient.get({url}, {query}) as Promise<TypedSearchResultDTO<ContactAdminDTO>>;
  }

  search(query: ContactSearchDTO): Promise<TypedSearchResultDTO<ContactAdminDTO>> {
    const url = `${this.data.apiUrl}/api/contacts/search`;
    return this.apiClient.get({url}, {query}) as Promise<TypedSearchResultDTO<ContactAdminDTO>>;
  }

  getMainData(query: WhosWhoSearchDTO): Promise<TypedSearchResultDTO<WhosWhoDTO>> {

    const url = `${this.data.apiUrl}${this.serviceUrl}/main-data`;
    return this.apiClient.get({url}, {query}) as Promise<TypedSearchResultDTO<WhosWhoDTO>>;
  }

  getPendingCustomers(): Promise<CustomerInternalInfoDTO[]> {
    const url = `${this.data.apiUrl}/api/customer/id-pending-approval`;
    return this.apiClient.get({url}) as Promise<CustomerInvoicesComponent[]>;
  }

  getPendingCount(): Promise<number> {
    const url = `${this.data.apiUrl}/api/whoswho/pending-count`;
    return this.apiClient.get({url}) as Promise<number>;
  }

  getUnreadCount(): Promise<number> {
    const url = `${this.data.apiUrl}/api/whoswho/unread-seller-messages-count`;
    return this.apiClient.get({url}) as Promise<number>;
  }

  getBidLimitCustomers(): Promise<BidLimitedCustomerInfoDTO[]> {
    const url = `${this.data.apiUrl}/api/customers/bid-limit-reached`;
    return this.apiClient.get({url}) as Promise<BidLimitedCustomerInfoDTO[]>;
  }

  getUnverifiedContacts(showDeleted = false): Promise<UnverifiedContactDTO[]> {
    const url = `${this.data.apiUrl}/api/contacts/unverified?showDeleted=${showDeleted}`; // k
    return this.apiClient.get({url}) as Promise<UnverifiedContactDTO[]>;
  }

  getAssignedOptions(assignees: ContactDTO[]) {
    let options = assignees.map((x) => {
      return {label: x.contactName, value: x.id, disabled: (x.statusId !== StatusEnum.Active)};
    });

    // Sort Assigned Options by statusid then alphabetically
    options = [...options.sort((a, b) => {
      if (a.disabled === b.disabled) {
        return a.label.localeCompare(b.label);
      }
      return a.disabled ? 1 : -1;
    })];

    return options;
  }
}
