// Main advert with its messages
export interface AdvertWithMessagesDTO {
  advertId: string;
  vendorContactId: string;
  vendorInfo: WhosWhoEnquiryContactDTO;
  primaryImageURL: string;
  vrm: string;
  vehicleColour: string;
  vehicleMake: string;
  vehicleModel: string;
  vehiclePlate: string;
  advertEndDateTime?: Date;
  saleTypeId: number;
  saleType: string;
  // Group messages by enquirer
  enquirerThreads: EnquirerThreadDTO[];
}

// Thread of messages from a single enquirer
export interface EnquirerThreadDTO {
  enquirer: WhosWhoEnquiryContactDTO;
  messages: ThreadMessageDTO[];
}

// Individual message in a thread
export interface ThreadMessageDTO {
  statusId: number;
  inMailId: string;
  inReplyToMailId?: string;
  isActioned: boolean;
  date: Date;
  isRead: boolean;
  message: string;
  subject: string;
  sender: WhosWhoEnquiryContactDTO;
}

export interface WhosWhoEnquiryContactDTO {
  id: string; // contactId
  name: string;
  email: string;
  phone: string;
  customerName: string;
  assignedTo: string;
}
