export interface AdViewSummaryDTO {
  sellerEmail: string;
  totalViews: number;
  customerDetails: AdViewSummaryCustomerDTO[];
}

export interface AdViewSummaryCustomerDTO {
  name: string | null;
  email: string | null;
  phone: string | null;
  numViews: number;
  latestViewDate: Date | null;
}
