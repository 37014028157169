import {Component, Input, OnInit} from '@angular/core';
import {AdviewService} from "../../../services";
import {AdminCustomerService, AdminUrlService} from "../services";
import {CreateCognitoUserDTO, User} from "../../../global/interfaces";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {
  AdminContactService,
  AdminCustomerInternalInfoService,
  ContactService,
  CustomerService
} from '../../../global/services';

@Component({
  selector: 'app-admin-account-creator',
  templateUrl: './admin-account-creator.component.html',
  styleUrls: ['./admin-account-creator.component.scss']
})
export class AdminAccountCreatorComponent implements OnInit {

  @Input() title: string;
  currentUser: User;
  email: any;
  contact: any;
  company: any;
  phone: any;

  createCognitoUser: CreateCognitoUserDTO;

  step1Form = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
    contactName: new FormControl('', [Validators.required]),
  });

  step2Form = new FormGroup({
    company: new FormControl('', [Validators.required]),
    phone: new FormControl('', [Validators.required]),
  });

  creatingStatus = 0;
  contactId: string;
  customerStatus: number;

  constructor(private adviewService: AdviewService,
              public adminUrl: AdminUrlService,
              public contactService: ContactService,
              public adminCustomerService: AdminCustomerService,
              public adminCustomerInternalInfoService: AdminCustomerInternalInfoService,
              public customerService: CustomerService,
              public adminContactService: AdminContactService) {
  }

  ngOnInit(): void {
  }

  createUser() {

    this.customerStatus = 0;
    this.creatingStatus = 1;
    this.contactId = null;

    this.adminContactService.createCognitoUser(this.step1Form.value as CreateCognitoUserDTO).then((x) => {

      this.createCognitoUser = x;

      if (x.userId) {
        if (x.userExists) {
          this.creatingStatus = 2;
        } else {

          this.adminContactService.create(this.step1Form.value as CreateCognitoUserDTO).then((x) => {

            this.createCognitoUser.contactId = x.contactId;

            this.creatingStatus = 3;
          }).catch((e) => {
            this.creatingStatus = 4;
          });
        }
      } else {
        this.creatingStatus = 4;
      }
    });

  }

  createAccount() {

    this.createCognitoUser.company = this.step2Form.value.company;
    this.createCognitoUser.phone = this.step2Form.value.phone;

    this.customerStatus = 1;

    this.adminContactService.initContactCustomer(this.createCognitoUser).then((response) => {

      if (response.customerId) {

        this.createCognitoUser.customerId = response.customerId;

        console.log("RESPONSE", response);

        // Make the customer active
        this.customerService.patchCustomer(response.customerId, {statusId: 1}).then(() => {
        });

        // Make the contact active
        this.contactService.patch(this.createCognitoUser.contactId, {statusId: 1}).then(() => {
        });

        // Change the TBA to CanCall
        this.adminCustomerInternalInfoService.patchCustomer(response.customerId, {whosWhoStatus: 1}).then(() => {
        });

        this.customerStatus = 2;

      }
    });
  }
}
