import {Injectable, Inject} from '@angular/core';
import {DOCUMENT} from "@angular/common";
import {DataServiceInterface} from "../global/services/interfaces";
import {AppComponent} from "../components/index";
import {environment} from "../../environments/environment";
import {DomainData} from "../global/shared";

@Injectable()
export class DataService implements DataServiceInterface {

  public apiUrl: string;
  public messageHubUrl: string;
  public googleMapsAPIKey: string;
  public stripePublicKey: string;
  public globals: DomainData;
  public engineiusKey: string;
  public engineiusDev: boolean;

  constructor(
    @Inject(DOCUMENT) private document,
  ) {

    if (environment.production) {

      const domain = document.domain;

      this.messageHubUrl = `${environment.messageHubUrl}`;
      this.apiUrl = AppComponent.globals.apiURL;
      this.googleMapsAPIKey = environment.googleMapsAPIKey;
      this.stripePublicKey = environment.stripePublicKey;
      this.engineiusKey = environment.engineiusKey;
      this.engineiusDev = false;
    } else {

      this.messageHubUrl = `${environment.messageHubUrl}`;
      this.apiUrl = `${environment.serviceUrl}`;
      this.googleMapsAPIKey = environment.googleMapsAPIKey;
      this.stripePublicKey = environment.stripePublicKey;
      this.engineiusKey = environment.engineiusKey;
      this.engineiusDev = true;
    }

    this.globals = AppComponent.globals;
  }


}
