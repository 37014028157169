// Think of MessageTypes as actions within a MessageGroup -> MessageArea
// eg. Contact -> InMails -> InMailSend

export enum MessageTypeEnum {
  BidCreate = 0,
  BidApprove,
  BidOutBid,
  BidHighBid,
  InMailSend,
  InMailRead, // send message to specified contact
  InMailDelete, // (message has been read and can be marked as read in db)
  InMailReceived,
  InMailGetCounts,
  AdvertUpdate,
  BidderUpdate,
  RostrumUpdate,
  CreateAdvertProgress,
  ImportAdvertImageProgress,
  FrontEndVersion,
  BidsSummary,
  UpcomingLots,
  PreviousLots,
  ContactsViewingSale,
  SaleUpdate,
  CurrentLotUpdate,
  BidderSaleCurrentLot,
  LotBidHistory,
  SetGGG,
  Ping,
  LiveSalesUpdate,
  MessageToRostrum,
  MessageFromRostrum,
  OfferAlert = 28,
  MicroAdvertUpdate = 29,
  CounterOfferAlert,
  ServerTime,
  ManageNegotiations,
  IdPendingDecrement,
  ShowPaymentIntentSetup,
  DealLimitReached,
  PaymentMethodCreated,
  ShowUpdatePaymentIntent,
  UnreadSellerMessagesDecrement
}
