import {
  AfterViewInit,
  Component,
  EventEmitter,
  HostListener,
  Input, OnChanges,
  OnDestroy,
  OnInit,
  Output, SimpleChanges,
  ViewChild
} from '@angular/core';
import {EventService, InMailService} from '../../../services';
import {IntersectionStatus} from "../../../utils/FromIntersectionObserver";
import {InMailFolderEnum} from "../../../global/enums/index";
import {GlobalConstants} from "../../../global/shared";
import {InMailDTO, User} from "../../../global/interfaces";
import {LoggerService, UserService} from "../../../global/services";
import {ModalDirective} from "ng-uikit-pro-standard";
import {animate, AUTO_STYLE, state, style, transition, trigger} from "@angular/animations";

@Component({
  selector: 'app-inmail-folder',
  templateUrl: './inmail-folder.component.html',
  styleUrls: ['./inmail-folder.component.scss'],
  animations: [
    trigger('collapse', [
      state('true', style({ height: AUTO_STYLE, visibility: AUTO_STYLE })),
      state('false', style({ height: '0', visibility: 'hidden' })),
      transition('false => true', animate(300 + 'ms ease-in')),
      transition('true => false', animate(300 + 'ms ease-out'))
    ])
  ]
})
export class InmailFolderComponent implements OnInit, OnDestroy, AfterViewInit, OnChanges {

  public inMailFolderEnum = InMailFolderEnum;
  isMobile = GlobalConstants.IsMobile;
  // tslint:disable-next-line:variable-name
  public isSelected: number;
  isLoading: boolean;
  showDeleteConfirm: boolean;
  perPage = 10;
  user: User;
  page = 1;
  mails: InMailDTO[];
  // tslint:disable-next-line:variable-name
  _selectedMail: InMailDTO;
  moreMail: boolean;
  loadingNextPage: boolean;

  @Input() chosenFolder: number;
  @Input() folder: number;
  @Output() refreshFolderCounts: EventEmitter<any> = new EventEmitter<any>();

  @Input("selectedMail") set selectedMail(value: InMailDTO) {

    this._selectedMail = value;

    if (value != null) {
      const emailId = "mail-" + value.id;
    }
  }

  get selectedMail() {
    return this._selectedMail;
  }

  logger = this.logService.taggedLogger(this.constructor?.name);

  constructor(
    private inMailService: InMailService,
    private eventService: EventService,
    private userService: UserService,
    private logService: LoggerService
  ) {

  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.chosenFolder) {
      this.folderChange();
    }
  }


  folderChange() {

    if (this.chosenFolder == this.folder) {

      // console.log("FOLDER CHANGE");

      this.isLoading = true;

      // load folder data
      this.loadFolder().then(() => {
        this.isLoading = false;
        this.page = 1;
        if (this.mails.length > 0) {

          this.viewMail(null, this.mails[0], false).then(() => {
          });
        }
      });
    }
  }

  async ngOnInit() {
    this.user = this.userService.CurrentUser;
  }

  @HostListener('window:beforeunload')
  ngOnDestroy() {
  }

  async ngAfterViewInit() {
  }

  async loadFolder() {
    this.mails = [];
    await this.appendFolder(1);
    this.logger.info("Loaded mail for ", this.getFolderName(), ": ", this.mails);
  }

  async appendFolder(page) {
    const email = await this.inMailService.getFolderMail(this.folder, page, this.perPage);
    this.mails.push(...email);

    // If we returned a full page, highlight we should get more next time
    this.moreMail = (email.length === this.perPage);
  }

  getFolderName(): string {
    return InMailFolderEnum[this.folder];
  }

  getSenderName(mail: InMailDTO, isOutbox) {
    if (!isOutbox) {
      if (this.isAdminMail(mail)) {
        return "Administrator";
      } else if (mail.toContact.id === this.user.contactId) {
        return "Buyer/Bidder";
      }
    } else {
      return this.user.name;
    }
  }

  isAdminMail(mail: InMailDTO) {
    return (mail?.fromContact?.id === this.user.contactId);
  }

  async viewMail(e, mail: InMailDTO, wasClicked: boolean = false) {

    if (this.selectedMail?.id === mail?.id) {
      this.selectedMail = null;
      console.log("UNSELECTING MAIL");
      return;
    }

    this.selectedMail = mail;
    this.selectedMail.body = this.selectedMail.body.replace("\n", "<br>");

    if (this.folder !== InMailFolderEnum.Outbox) {

      const updated = await this.inMailService.markAsRead(mail.id);

      // This updates the UI to show it was read
      this.selectedMail.isRead = true;

      // refresh the folder view if record was altered (i.e. not already read)
      if (updated) {

        // Not sure we still need this ?
        // await this.loadFolder();

        // parent component should refresh folder counts
        this.refreshFolderCounts.emit();
      }
    }
  }

  async delete(event: MouseEvent, mail: InMailDTO) {

    event.preventDefault();
    event.stopPropagation();

    // if this is the deleted folder, prompt for confirmation of permanent deletion
    if (this.folder === InMailFolderEnum.Deleted) {
      this.showDeleteConfirm = true;
    } else {
      await this.doDelete(mail);
    }
  }

  async confirmDelete() {
    await this.doDelete(this.selectedMail);
  }

  async doDelete(mail: InMailDTO) {

    console.log("DELETING ", mail);

    await this.inMailService.deleteInMail(mail).then(() => {
      // Splice the email out of the list
      this.mails = this.mails.filter((x) => x.id !== mail.id);
    });

    // refresh the folder view
    // await this.loadFolder(); // Do we need to ??

    // parent component should refresh folder counts
    this.refreshFolderCounts.emit();

    if (this.folder !== InMailFolderEnum.Deleted) {
      this.eventService.ReloadInMail.emit(InMailFolderEnum.Deleted);
    }

    this.selectedMail = null;
  }

  cancelDelete() {
    this.showDeleteConfirm = false;
  }

  async moveToInbox(e) {

    e.stopPropagation();
    e.preventDefault();
    // move selected mail to inbox
    await this.moveToFolder(InMailFolderEnum.Inbox);
  }

  async moveToSaved(e) {
    e.stopPropagation();
    e.preventDefault();
    // move selected mail to saved
    await this.moveToFolder(InMailFolderEnum.Saved);
  }

  async moveToFolder(toFolder: InMailFolderEnum) {

    // Splice the email out fo the list
    this.mails = this.mails.filter((x) => x.id !== this.selectedMail.id);

    await this.inMailService.moveToFolder(this.selectedMail, toFolder);

    // refresh the folder view
    // await this.loadFolder();

    // parent component should refresh folder counts
    this.refreshFolderCounts.emit();

    this.eventService.ReloadInMail.emit(toFolder);
    this.selectedMail = null;
  }

  reply() {

  }

  onVisibilityChanged(status: IntersectionStatus) {

    if (status === IntersectionStatus.Visible && this.moreMail) {

      this.page = this.page + 1;

      this.loadingNextPage = true;
      this.appendFolder(this.page).then(() => {
        this.loadingNextPage = false;
      });
    }
  }
}
