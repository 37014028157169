export interface CreateCognitoUserDTO {
  email: string;
  company: string;
  phone: string;
  contactName: string;
  tempPassword: string;

  userExists?: boolean;
  userId: string;
  contactId: string;
  customerId: string;
}
